$(document).ready(function() {
    //Declare normal variable javascript
    //Hide element when smaller than 1025

    if ($(window).width() < 1025) {
        $(".bottom-header").fadeIn(function() {
            $(".bottom-header").css({
                display: "flex"
            });
        });
    }
    //Toggle Search
    $(".search-toggle").on("click", function() {
        $(".searchbox").toggleClass("active");
    });
    //Library init
    $(".lightgallery").lightGallery();
    //Declare function Javascript
    sideNavigation();
    tabActive();
    if ($(window).width() > 1024) {
        const $menu = $(".searchbox");
        $(document).mouseup(e => {
            if (!$menu.is(e.target) && // if the target of the click isn't the container...
                $menu.has(e.target).length === 0
            ) {
                // ... nor a descendant of the container
                $menu.removeClass("active");
            }
        });
    }
    swiperInit();
    mappingMainMenu();
    mappingSearch();
    toggleMobileMenu();
    mobileNavigation();
});
//Check if windows size large then 1024 then these function will be execute
if ($(window).width() > 1024) {
    const $menu = $(".searchbox");
    $(document).mouseup(e => {
        if (!$menu.is(e.target) && // if the target of the click isn't the container...
            $menu.has(e.target).length === 0
        ) {
            // ... nor a descendant of the container
            $menu.removeClass("active");
        }
    });
}
// Remove when click outside the circle
function imageZoomn() {}

function tabActive() {
    $(".tab-navigation li a").on("click", function() {
        $(this)
            .parents(".tab-list-navigation")
            .find("li")
            .removeClass("active");
        $(this)
            .parents("li")
            .addClass("active");

        var display = $(this).attr("data-type");
        $(".tab-item").removeClass("active");
        $("#" + display).addClass("active");
    });
}

function mobileNavigation() {
    $(".main-nav > li > .title em").click(function(e) {
        e.preventDefault();
        if (!$(this)
            .parent()
            .parent()
            .hasClass("active")
        ) {
            $(".main-nav li .nav-sub").slideUp();
            $(this)
                .parent()
                .next()
                .slideToggle();
            $(".main-nav li").removeClass("active");
            $(this)
                .parent()
                .parent()
                .addClass("active");
        } else {
            $(this)
                .parent()
                .next()
                .slideToggle();
            $(".main-nav li").removeClass("active");
        }
    });
}

function sideNavigation() {
    $(".side-navigation > li > .title em").click(function(e) {
        e.preventDefault();
        if (!$(this)
            .parent()
            .parent()
            .hasClass("active")
        ) {
            $(".side-navigation li .nav-sub").slideUp();
            $(this)
                .parent()
                .next()
                .slideToggle();
            $(".side-navigation li").removeClass("active");
            $(this)
                .parent()
                .parent()
                .addClass("active");
        } else {
            $(this)
                .parent()
                .next()
                .slideToggle();
            $(".side-navigation li").removeClass("active");
        }
    });
}

window.onscroll = function() {
    fixHeader();
    fixSuNavigation();
};

function fixHeader() {
    if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
    ) {
        $("header").addClass("minimize");
    } else {
        $("header").removeClass("minimize");
    }
}

function fixSuNavigation() {
    if ($(window).width() < 1025) {
        if (
            document.body.scrollTop > 300 ||
            document.documentElement.scrollTop > 300
        ) {
            $(".about-us-company-information .tab-navigation-wrapper").addClass(
                "scroll"
            );
        } else {
            $(
                ".about-us-company-information .tab-navigation-wrapper"
            ).removeClass("scroll");
        }
    }
}

function mappingSearch() {
    return new MappingListener({
        selector: ".search-wrapper",
        mobileWrapper: ".mobile-wrapper .container",
        mobileMethod: "appendTo",
        desktopWrapper: ".right-wrapper",
        desktopMethod: "appendTo",
        breakpoint: 1025
    }).watch();
}

function mappingMainMenu() {
    return new MappingListener({
        selector: ".main-nav",
        mobileWrapper: ".mobile-wrapper .container",
        mobileMethod: "appendTo",
        desktopWrapper: ".main-navigation-wrapper",
        desktopMethod: "appendTo",
        breakpoint: 1025
    }).watch();
}

function toggleMobileMenu() {
    $(".mobile-toggle").on("click", function() {
        $(".mobile-wrapper").toggleClass("active");
    });
}

function swiperInit() {
    var mySwiper = new Swiper(".banner-fluid-caption .swiper-container", {
        // Optional parameters
        loop: true,
        slidesPerView: 1,
        effect: "fade",
        fadeEffect: {
            crossFade: true
        },
        speed: 750,
        pagination: {
            el: ".swiper-pagination.banner-fluid-caption-pagination",
            type: "bullets",
            clickable: "true"
        }
    });
    var homePartner = new Swiper(".home-partner .swiper-container", {
        // Optional parameters
        speed: 1205,
        spaceBetween: 30,
        autoplay: {
            delay: 3000
        },
        breakpointsInverse: true,
        breakpoints: {
            320: {
                slidesPerView: 1
            },
            350: {
                slidesPerView: 2
            },
            576: {
                slidesPerView: 3
            },
            768: {
                slidesPerView: 4
            },
            1025: {
                slidesPerView: 5
            }
        }
    });
    var certificate = new Swiper(".swiper-trinity  .swiper-container", {
        slidesPerView: 3,
        autoplay: {
            delay: 3200
        },
        spaceBetween: 40,
        speed: 750,
        breakpointsInverse: true,
        breakpoints: {
            320: {
                slidesPerView: 1
            },
            576: {
                slidesPerView: 3
            }
        },
        loop: false
    });
    //Certificate
    var certificate = new Swiper(".swiper-double .swiper-container", {
        slidesPerView: 2,
        autoplay: {
            delay: 3200
        },
        spaceBetween: 40,
        speed: 750,
        breakpointsInverse: true,
        breakpoints: {
            320: {
                slidesPerView: 1
            },
            576: {
                slidesPerView: 2
            }
        },
        loop: false,
        navigation: {
            nextEl: ".certificate-next",
            prevEl: ".certificate-prev"
        }
    });
    //Certificate
    var otherSwiper = new Swiper(".others-news .swiper-container", {
        spaceBetween: 30,
        speed: 750,
        breakpointsInverse: true,
        breakpoints: {
            320: {
                slidesPerView: 1
            },
            576: {
                slidesPerView: 2
            },
            1025: {
                slidesPerView: 3
            }
        },
        loop: false,
        navigation: {
            nextEl: ".other-news-next",
            prevEl: ".other-news-prev"
        }
    });
    var topBannerSwiper = new Swiper(".top-banner .swiper-container", {
        // Optional parameters
        loop: true,
        slidesPerView: 1,
        effect: "fade",
        fadeEffect: {
            crossFade: true
        },
        speed: 750
    });
    var galleryThumbs = new Swiper(".swiper-history-thumbs .swiper-container", {
        spaceBetween: 0,
        slidesPerView: 5,
        loop: false,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        navigation: {
            nextEl: ".thumbs-navigation-next",
            prevEl: ".thumbs-navigation-prev"
        }
    });

    var galleryTop = new Swiper(".swiper-history-main .swiper-container", {
        centeredSlides: true,
        spaceBetween: 15,
        breakpointsInverse: true,
        breakpoints: {
            320: {
                slidesPerView: 1
            },
            1025: {
                slidesPerView: 1.7
            }
        },
        loop: false,
        navigation: {
            nextEl: ".history-navigation-next",
            prevEl: ".history-navigation-prev"
        },
        thumbs: {
            swiper: galleryThumbs
        }
    });
    var statusTechnologySwiper = new Swiper(
        ".swiper-status-technology .swiper-container", {
            slidesPerView: 1,
            effect: "fade",
            autoplay: {
                delay: 3000
            },
            fadeEffect: {
                crossFade: true
            },
            speed: 750,
            loop: false,
            navigation: {
                nextEl: ".swiper-navigation-prev",
                prevEl: ".swiper-navigation-next"
            }
        }
    );

    $(".swiper-nested .swiper-container").each(function(index, element) {
        var $this = $(this);
        $this.addClass("instance-" + index);
        $this.find(".about-us-prev").addClass("btn-prev-" + index);
        $this.find(".about-us-next").addClass("btn-next-" + index);

        var swiper = new Swiper(".instance-" + index, {
            speed: 750,
            slidesPerView: 1,
            direction: "vertical",

            scrollbar: {
                el: ".swiper-scrollbar",
                hide: true
            }
            // your settings ...
        });
    });

    var productThumbs = new Swiper(
        ".swiper-product-thumbs  .swiper-container", {
            spaceBetween: 10,
            freeMode: false,
            breakpointsInverse: true,
            breakpoints: {
                320: {
                    slidesPerView: 2.5,
                    direction: "horizontal",
                    spaceBetween: 10
                },
                576: {
                    slidesPerView: 3.5,
                    direction: "horizontal",
                    spaceBetween: 10
                },
                1025: {
                    slidesPerView: 4,
                    direction: "vertical"
                },
                1440: {
                    slidesPerView: 4,
                    direction: "vertical"
                }
            },
            centeredSlides: true,
            direction: "vertical",
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            navigation: {
                nextEl: ".product-thumb-next",
                prevEl: ".product-thumb-prev"
            }
        }
    );

    var productMain = new Swiper(".swiper-product-main .swiper-container", {
        slidesPerView: 1,
        centeredSlides: true,
        effect: "fade",
        fadeEffect: {
            crossFade: true
        },
        speed: 750,
        loop: false,
        thumbs: {
            swiper: productThumbs
        }
    });
}
$(document).on("click", ".wrapper video", function(e) {
    $(this).removeAttr("poster");
    var video = $(this).get(0);
    if (video.paused === false) {
        video.pause();
        $(this)
            .parent()
            .find(".play__icon")
            .fadeIn();
        $(this)
            .parents(".wrapper")
            .find(".swiper-caption")
            .fadeIn();
    } else {
        video.play();
        $(this)
            .parent()
            .find(".play__icon")
            .fadeOut();
        $(this)
            .parents(".wrapper")
            .find(".swiper-caption")
            .fadeOut();
    }
    return false;
});